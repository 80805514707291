@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Light";
    src: "Montserrat-Light",
    url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Medium";
    src: "Montserrat-Medium",
    url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Bold";
    src: "Montserrat-Bold",
    url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}